import Vue from "vue";
import Vuex from "vuex";

import apiClient from "@/plugins/apiClient.js";
import createWebSocketPlugin from "@/plugins/webSocket.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    conversations: [],
    loader: "",
    isActive: 0,
    activeName: "",
    userConversations: []
  },
  mutations: {
    SET_CONVERSATION(state, conversations) {
      state.conversations = conversations;
    },
    SET_LOADER(state) {
      state.loader = "";
    },
    RESET_LOADER(state) {
      state.loader = "none";
    },
    SET_ACTIVEUSER(state, name) {
      state.activeName = name;
    },
    SET_ACTIVECONVERSATION(state, conversations) {
      state.userConversations = conversations;
    },
    SET_ACTIVEID(state, id) {
      state.isActive = id;
    }
  },
  actions: {
    fetchConversations(context) {
      apiClient
        .getConversations()
        .then(response => {
          console.log("from state");
          context.commit("SET_CONVERSATION", response.data);
          context.commit("RESET_LOADER");
        })
        .catch(() => console.log("error"));
    }
  },
  plugins: [createWebSocketPlugin()]
});
