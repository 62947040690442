<template>
  <v-app id="inspire">
    <div id="app">
      <AppBar />
      <div class="update-dialog" v-if="prompt">
        <div class="update-dialog__content">
          A new version is found. Refresh to load it?
        </div>
        <div class="update-dialog__actions">
          <button
            class="update-dialog__button update-dialog__button--confirm"
            @click="update"
          >
            Update
          </button>
          <button
            class="update-dialog__button update-dialog__button--cancel"
            @click="prompt = false"
          >
            Cancel
          </button>
        </div>
      </div>

      <router-view />
    </div>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
export default {
  components: {
    AppBar
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  },
  data() {
    return {
      prompt: false
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
  }
};
</script>

<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
