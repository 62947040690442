import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://joe-bot-webhook.herokuapp.com/api/messenger",
  withCredentials: false
});

export default {
  getConversations() {
    return apiClient.get("/conversations");
  },

  sendMessage(message, psid) {
    return apiClient.post("/sendMessage", {
      message: message,
      psid: psid
    });
  },

  reverseBot(psid) {
    return apiClient.post("/reverseBot", {
      psid
    });
  }
};
