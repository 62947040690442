<template>
  <v-toolbar disable-resize-watcher color="cyan">
    <v-app-bar-nav-icon></v-app-bar-nav-icon>

    <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "AppBar"
};
</script>
