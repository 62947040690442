<template>
  <div class="text-center">
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EventBus, { ACTIONS } from "../EventBus/index";

export default {
  name: "SnackBar",
  data: () => ({
    text: "",
    snackbar: false,
    timeout: 3000
  }),
  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, message => {
      this.text = message;
      this.snackbar = true;
    });
  }
};
</script>

<style scoped></style>
