<template>
  <v-card min-height="100px" max-width="100%" class="mx-auto">
    <v-progress-linear
      v-bind:style="{ display: loader }"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="16"
    />
    <v-list three-line>
      <v-list-item
        v-ripple
        v-for="conversation in conversations"
        v-bind:key="conversation._id"
        @click="
          reverseMessage(
            conversation.profile_id,
            conversation.profile_name,
            conversation.isBotActive
          )
        "
        v-bind:class="{ active: isActive == conversation.profile_id }"
      >
        <v-list-item-avatar>
          <v-img src="images/profiles/kim.jpeg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ conversation.profile_name }}</v-list-item-title
          >
          <v-list-item-subtitle class="lastmessage">{{
            conversation.conversations.slice(-1).pop().message
          }}</v-list-item-subtitle>

          <v-list-item-subtitle>{{
            conversation.conversations.slice(-1).pop().createdAt
              | moment("from", "now")
          }}</v-list-item-subtitle>

          <v-divider></v-divider>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import Vue from "vue";

import { mapState } from "vuex";

//import plugins

Vue.use(require("vue-moment"));

export default {
  name: "ConversationList",
  props: ["search"],
  methods: {
    reverseMessage(profile_id, profile_name, bot_status) {
      let id = (localStorage.id = profile_id);
      let activename = `${profile_name} Bot Active: ${bot_status}`;
      let activeUser = this.conversations.find(o => o.profile_id == id);
      this.$store.commit("SET_ACTIVEUSER", activename);
      this.$store.commit("SET_ACTIVECONVERSATION", activeUser.conversations);
      this.$store.commit("SET_ACTIVEID", id);
      this.$router.push({ name: "Chat" });
    }
  },
  watch: {
    conversations: function() {
      if (localStorage.id) {
        let activeUser = this.conversations.find(
          o => o.profile_id == localStorage.id
        );
        let activename = `${activeUser.profile_name} | Bot Active: ${activeUser.isBotActive}`;
        this.$store.commit("SET_ACTIVEUSER", activename);
        console.log(activeUser.conversations);
        this.$store.commit("SET_ACTIVECONVERSATION", activeUser.conversations);
      }
    }
  },
  beforeCreate() {
    localStorage.removeItem("id");
    this.$store.dispatch("fetchConversations");
  },
  computed: {
    // filteredConversation() {
    //   return this.conversations.filter(conversation => {
    //     return conversation.profile_name
    //       .toLowerCase()
    //       .includes(this.search.toLowerCase());
    //   });
    // },
    ...mapState(["conversations", "loader", "isActive"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.lastmessage {
  font-size: 0.8rem;
}
.v-progress-circular {
  margin: 10rem;
}

.v-card {
  height: 100%;
}

.v-application .mx-auto {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
</style>
