import io from "socket.io-client";
let socket = io("https://joe-bot-webhook.herokuapp.com");

export default function createWebSocketPlugin() {
  return store => {
    socket.on("reload", function() {
      console.log("socket called");
      store.dispatch("fetchConversations");
    });
  };
}
