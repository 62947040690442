<template>
  <div>
    <UserList />
  </div>
</template>

<script>
// @ is an alias to /src
import UserList from "@/components/chat/UserList.vue";

export default {
  name: "Home",
  components: {
    UserList
  }
};
</script>
